//////////////////////////////////////////////////////////////
// Components
//////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////
// DateTimeInterval
//////////////////////////////////////////////////////////////
.DateTimeInterval{
    .Container{width: calc(50% - 10px); display: inline-block; margin-right: 10px;}
}


//////////////////////////////////////////////////////////////
// VisualFeedback
//////////////////////////////////////////////////////////////
.VisualFeedback{
    position: fixed; z-index: 9999; padding: 0; margin: 0; max-width: 800px; max-height: 500px; overflow: auto;
}

.VisualFeedback[data-feedback-type="error"]{overflow: auto; top: 50%; left: 50%; transform: translate(-50%,-50%);}
.VisualFeedback[data-feedback-type="warning"]{overflow: auto; top: 50%; left: 50%; transform: translate(-50%,-50%);}
.VisualFeedback[data-feedback-type="info"]{top: auto; left: auto; right: 0; bottom: 0;}

//////////////////////////////////////////////////////////////
// InputNumber
//////////////////////////////////////////////////////////////
.InputNumber{
    text-align: right;
}

//////////////////////////////////////////////////////////////
// Loading
//////////////////////////////////////////////////////////////
.Loading{
    position: absolute; top: 0; left: 0; z-index: 9999; display: none; pointer-events: none; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.7);
    .Img{width: 128px; height: 128px; position: relative; top: calc(50% - 64px); left: calc(50% - 64px); color: #000  }
}


//////////////////////////////////////////////////////////////
// DATE PICKER OVERRIDE
//////////////////////////////////////////////////////////////
/*Datepicker is showing too small in size when triggered  because of the component sizes are set in rem units.*/
.react-datepicker-popper{z-index: 3; width: max-content;}
.react-datepicker {    font-size: 1em;  }
.react-datepicker__header {    padding-top: 1em;  }
.react-datepicker__month {    margin: 0.5em 1em;  }
.react-datepicker__day-name, .react-datepicker__day {    width: 1.9em;    line-height: 1.9em;    margin: 0.166em;  }
.react-datepicker__current-month {    font-size: 1em;  }
.react-datepicker__navigation {    top: 1em;    line-height: 1.7em;    border: 0.45em solid transparent;  }
.react-datepicker__navigation--previous {    border-right-color: #ccc;    left: 1em;  }
.react-datepicker__navigation--next {    border-left-color: #ccc;    right: 1em;  }
.react-datepicker-time__header {    font-size: 0.8em;  }