// Opening and closing motion for conditional fieldset
@-webkit-keyframes conditionalOpen {
    from { transform: scaleY(0) }
    to { transform: scaleY(1) }
}
@-moz-keyframes conditionalOpen {
    from { transform: scaleY(0) }
    to { transform: scaleY(1) }
}
@keyframes conditionalOpen {
    from { transform: scaleY(0) }
    to { transform: scaleY(1) }
}

@-webkit-keyframes conditionalClose {
    from { transform: scaleY(1) }
    to { transform: scaleY(0) }
}
@-moz-keyframes conditionalClose {
    from { transform: scaleY(1) }
    to { transform: scaleY(0) }
}
@keyframes conditionalClose {
    from { transform: scaleY(1) }
    to { transform: scaleY(0) }
}

@font-face {
    font-family: 'rakkas';
    src: url('rakkas/Rakkas-Regular.ttf');
}

@font-face {
    font-family: 'merriweather-bold';
    src: url('merriweather/Merriweather-Bold.ttf');
}

@font-face {
    font-family: 'merriweather-regular';
    src: url('merriweather/Merriweather-Regular.ttf');
}

body{
    font-family: merriweather-regular;

    .MainView{
        display: grid;
        grid-template-rows: auto 1fr auto;
        min-height: 100vh; margin: 0; 
        .anchor{height: 50px;}
        
        header{             min-height:56px;        }
        .hide{min-height: auto; display: none;}
        
        footer{
            min-height:50px;  width: 100%;
        }

        .photo-slider{
            max-width: 70%; min-width: 250px;    margin: auto;    columns: 6 70px;    column-gap: .5rem;    
            .photo-slider-item{
                margin: .5rem;  display: inline-block;  width: 100%;  border-radius: .5rem;
            }
            .photo-slider-item:hover{
                transform: scale(2.5);
            }
        }

        .my-cart-view{
            padding: 1rem; 
            .cart-items{
                width: 70%;
                .cart-item{
                    border-top: 1px solid #f0f0f0; border-bottom: 1px solid #f0f0f0; 
                    margin-bottom: 1rem; padding: .5rem; margin: auto; display: grid;
                    grid-template-columns: 160px auto; gap: 1rem;
                }
            }

            .cart-checkout{
                background-color: #f0f0f0; border-radius: 1rem; padding: 1rem;
            }
            
        }

        .payment-order-view{
            padding: 1rem; 
            .payment-items{
                width: 70%;
              
            }

            .order-summary{
                background-color: #f0f0f0; border-radius: 1rem; padding: 1rem;
            }
            
        }
    }
}
/*
.BookReaderV1{
    nav.navbar{
        position: absolute; width: 100%;
    }

    .show{
      //  display: flex!important;
        -webkit-animation: conditionalOpen .5s normal forwards ease-in-out;
        -moz-animation: conditionalOpen .5s normal forwards ease-in-out;
        animation: conditionalOpen .5s normal forwards ease-in-out;
        -webkit-transform-origin: 50% 0%;
        -moz-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
    }

    .hide{
        //display: none!important;
        -webkit-animation: conditionalClose .5s normal forwards ease-in-out;
        -moz-animation: conditionalClose .5s normal forwards ease-in-out;
        animation: conditionalClose .5s normal forwards ease-in-out;
        -webkit-transform-origin: 100% 0%;
        -moz-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }

    .pagination{
        position: absolute; bottom: 0; height: 70px; width: 100%; background-color: #212529; padding: 1rem; display: flex; align-items: center; flex-wrap: wrap; justify-content: center;
        .pageNumber{color: #FFF;}
    }    
}*/

.BookReaderV2{  
    nav.navbar{
        position: fixed; width: 100%;
        .dropdown{
            .dropdown-menu{
                left: -155px; width: 361px; max-height: 90vh; overflow-y: auto; scrollbar-width: thin;
                .dropdown-item{ text-wrap: wrap; line-height: 1.7rem;}
            }
        }
    }

    .show{
      //  display: flex!important;
        -webkit-animation: conditionalOpen .5s normal forwards ease-in-out;
        -moz-animation: conditionalOpen .5s normal forwards ease-in-out;
        animation: conditionalOpen .5s normal forwards ease-in-out;
        -webkit-transform-origin: 50% 0%;
        -moz-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
    }

    .hide{
        //display: none!important;
        -webkit-animation: conditionalClose .5s normal forwards ease-in-out;
        -moz-animation: conditionalClose .5s normal forwards ease-in-out;
        animation: conditionalClose .5s normal forwards ease-in-out;
        -webkit-transform-origin: 100% 0%;
        -moz-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }

   /* .pagination{
        position: absolute; bottom: 0; height: 70px; width: 100%; background-color: #212529; padding: 1rem; display: flex; align-items: center; flex-wrap: wrap; justify-content: center;
        .pageNumber{color: #FFF;}
    }  */  
}

.bypass-navbar{margin-top: 56px;}

.card-book{
    img{max-width: 180px; max-height: 191px; }
    .info{
        text-align: left;
        .title{margin-top: .5rem; font-size: 13px; line-height: 20px; font-weight: 600; color: #333;}
        .author{margin-top: .5rem; font-size: 13px; line-height: 20px; font-weight: 200; color: #333;}
        .type{margin-top: .5rem; color: #ffa107; font-weight: 700; font-size: 13px;}
        .price{
            .currency{font-size: 12px;}
            .amount{font-size: 22px;}
        }
        .option{font-size: 10px;}
    }
}
.card-book-tile{
    width: 205px; background-color: #f7f7f7; margin:.2rem; border-radius: .5rem; padding: .5rem; text-align: center;
}
.card-book-sale:hover{
    cursor: pointer;
    background-color: #f1f1f1;
}

.carousel {
    .carousel-indicators{
        margin-bottom: -1rem;
        button{background-color: #000;}
    }
}

@media print {
    .d-print-none {
        display: none!important;
    }
}
//////////////////////////////////////////////////////////////
// DlgLogin
//////////////////////////////////////////////////////////////
.sign-in-view{
    @extend .bypass-navbar; 
    display: flex;      align-items: center;   color: #FFF; height: fit-content;
    background-color: #333333;  border-radius: .5rem; padding: 1.5rem; 
    width: 60vw; max-width: 550px; min-width: 320px; margin: 1rem auto 1rem auto;
}


/**********************************************************
/ tablets layout
/*********************************************************/
@media screen and (min-width: 768px) and (max-width: 990px){ 
 
}

/**********************************************************
/ tablets layout
/*********************************************************/
@media screen and (min-width: 500px) and (max-width: 767px){ 
   
}

/**********************************************************
/ Smartphone layout
/*********************************************************/
@media screen and (max-width: 500px){
    .card-book-tile{
        width: 140px; 
        img{max-width: 115px; min-height: 136px; }
        
    }
}